<script setup>
import TextInput from '@/Components/TextInput.vue';
import { ref } from 'vue';

defineProps({
    id: String,
    autocomplete: String,
    modelValue: String,
});

const emit = defineEmits(['input']);

const showPassword = ref(false);
</script>

<template>
    <div class="relative flex items-center px-6 py-4 bg-gray2 rounded-[100px]">
        <svg class="flex-shrink-0 mr-3" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
            <path
                fill="#1C1C1C"
                d="M15.6 6.74V5.6a5.6 5.6 0 0 0-11.2 0v1.14A4 4 0 0 0 2 10.4v4.8a4.005 4.005 0 0 0 4 4h8a4.005 4.005 0 0 0 4-4v-4.8a4 4 0 0 0-2.4-3.66ZM6 5.6a4 4 0 0 1 8 0v.8H6v-.8Zm10.4 9.6a2.4 2.4 0 0 1-2.4 2.4H6a2.4 2.4 0 0 1-2.4-2.4v-4.8A2.4 2.4 0 0 1 6 8h8a2.4 2.4 0 0 1 2.4 2.4v4.8Z"
            />
            <path fill="#1C1C1C" d="M10 11.2a.8.8 0 0 0-.8.8v1.6a.8.8 0 1 0 1.6 0V12a.8.8 0 0 0-.8-.8Z" />
        </svg>
        <TextInput
            :id="id"
            :value="modelValue"
            :type="showPassword ? 'text' : 'password'"
            class="block w-full"
            placeholder="•••••••••"
            required
            :autocomplete="autocomplete"
            @input="emit('update:modelValue', $event.target.value)"
        />
        <svg
            class="absolute cursor-pointer right-6 top-0 bottom-0 m-auto"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            @click="showPassword = !showPassword"
        >
            <path
                fill="#D2D2D2"
                fill-rule="evenodd"
                d="M19.216 8.923c-.931-1.153-4.392-4.924-9.45-4.924-1.29-.004-2.57.237-3.77.71l-2.572-2.53A.887.887 0 0 0 2.18 3.423l13.93 13.93a.886.886 0 0 0 1.594-.596.886.886 0 0 0-.352-.646l-2.085-2.13a14.242 14.242 0 0 0 3.948-3.372 1.331 1.331 0 0 0 0-1.686Zm-5.279 3.77-1.775-1.774c.177-.359.268-.754.266-1.154a2.661 2.661 0 0 0-2.661-2.661c-.4-.002-.795.089-1.154.266L7.371 6.128a8.473 8.473 0 0 1 2.396-.355c3.905 0 6.787 2.75 7.852 3.992a13.176 13.176 0 0 1-3.682 2.929ZM3.734 6.928A.887.887 0 0 0 2.49 8.169l6.655 6.654a.887.887 0 0 0 1.242-1.242L3.734 6.927Zm-2.5 2.016a.843.843 0 0 1 .281.202l3.108 3.105a.887.887 0 0 1-1.245 1.245L.273 10.387a.842.842 0 0 1 0-1.242.843.843 0 0 1 .96-.202Z"
                clip-rule="evenodd"
            />
        </svg>
    </div>
</template>
